/* eslint-disable max-lines */
import { AlertType, AppConstants } from 'src/app/constants/AppConstants';
import {
  ButtonKey,
  InputFieldKey,
  inputValue,
  modals,
  showError,
} from './declarations';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  NavigationScreen,
  SideBarTabs,
} from 'src/app/constants/navigation-screen';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConstants } from 'src/app/constants/ApiConstants';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CommonService } from 'src/app/services/common/common.service';
import { NetworkResponseType } from 'src/app/services/network/NetworkResponse';
import { ProfileData } from 'src/app/models/ProfileData';
import { ProfileModel } from 'src/app/models/ProfileModel';
import { RedirectHandlerService } from 'src/app/services/redirect-handler/redirecthandler.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
})
export class SidebarComponent implements OnInit {
  /** Property to hold the theme */
  theme = 'light';

  /** Property to hold the Modals for Profile, Logout, Edit Profile, Change Password */
  modals: any;

  /** Property to handle input value */
  public inputValue: any;

  /** Property to hold the sidebar tabs */
  sideBarTabs = [];

  /** Property to hold the active sidebar tabs */
  activeTab = SideBarTabs.DASHBOARD;

  /** Property to hold the sidebar tab subscription */
  tabSubscription$: Subscription;

  /** Property to hold the sidebar collapsed/non-collapsed */
  isSideBarCollapsed = false;

  /** Property to hold the modal header message */
  successMessage = '';

  /** Property to hold profile data */
  profileData: ProfileData;

  /** Property to hold profile data */
  profile: ProfileData;

  /** Property to hold if device is mobile */
  isMobile = false;

  /** Property to handle button type */
  public buttonKey = ButtonKey;

  /** Property to handle input field type */
  public inputFieldKey = InputFieldKey;

  /** Property to handle error messages */
  showError: any;

  /** Property to hold modal button status to update profile/change password */
  isUpdateProfile = true;

  /** Property to hold name initials */
  nameInitials = '';

  /** Primary Constructor */
  constructor (
    private commonService: CommonService,
    private sharedService: SharedService,
    private RedirectHandlerService: RedirectHandlerService,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthenticationService,
  ) {
    this.checkDevice();
    this.setSidebarStatus();
    this.modals = modals;
    this.inputValue = inputValue;

    this.tabSubscription$ = sharedService.activeTab.subscribe((tab) => {
      this.activeTab = tab;
    });
  }

  // Init Method
  ngOnInit (): void {
    // Initialize Show Error
    this.showError = showError;

    // API Call - Get Profile
    this.commonService.getProfile().then((profileModel: ProfileModel) => {
      const data = this.sharedService.createDeepCopy(profileModel?.data);
      this.profile = { ...data, name: data?.name ?? '' };

      // Set Profile Picture
      this.setProfilePicture();

      // Set Sidebar Tabs with permission checks
      this.setSidebarTabs();
    });

    // Set Profile Data
    this.sharedService.profileData.subscribe((profile) => {
      const data = this.sharedService.createDeepCopy(profile?.data);
      this.profileData = {
        ...data,
        name: data?.name ?? '',
        contact: {
          ...data?.contact,
          email: data?.contact?.email ?? '',
          phone: {
            ...data?.contact?.phone,
            prefix: data?.contact?.phone?.prefix ?? '',
            number: data?.contact?.phone?.number ?? '',
          },
        },
        fileName: this.sharedService?.extractFileNameFromUrl(data?.picture),
      };

      // Set Initial Active Tab
      this.setInitialActiveTab();
    });
  }

  /**
   * Set Profile Picture
   * @returns Profile Picture URL
   */
  setProfilePicture () {
    if (this.sharedService.isValidImageUrl(this.profileData?.picture)) {
      this.nameInitials = '';
    } else {
      this.nameInitials = this.sharedService.getNameInitials(
        this.profileData?.name,
      );
    }
  }

  /** Check window on resize */
  @HostListener('window:resize', [ '$event' ])
  onWindowResize (event: any) {
    this.checkDevice();
    this.setSidebarStatus();
  }

  /**
   * Check Device and Set Device
   */
  private checkDevice () {
    this.isMobile = window.innerWidth <= 992;
    this.commonService.updateIsMobileValue(this.isMobile);
  }

  /**
   * Toggle Sidebar
   */
  toggleSidebar (): void {
    // Update Sidebar open/collapsed status
    this.isSideBarCollapsed = !this.isSideBarCollapsed;

    // Update Sidebar open/collapsed status
    this.sharedService.sideBarStatus.next(this.isSideBarCollapsed);
  }

  // Tab Click Navigation
  navigate (tab: {
    title: string;
    key?: string;
    image: string;
    dropDownActive: boolean;
  }) {
    this.sharedService.activeTab.next(tab.key as any);
    if (tab.key === SideBarTabs.PROFILE) {
      // Show Profile Modal
      modals.profileModal = !modals.profileModal;
    } else if (tab.key === SideBarTabs.LOGOUT) {
      // Show Logout Modal
      modals.logoutModal = !modals.logoutModal;
    } else {
      this.RedirectHandlerService.redirectTo(tab.key as any);
    }
  }

  // Switch Theme Button Click
  onSwitchTheme (event: any) {
    this.sharedService.switchTheme(event?.target?.checked);
    this.theme = this.sharedService.isDarkTheme ? 'dark' : 'light';
    // Set Sidebar Tabs
    this.setSidebarTabs();
  }

  /**
   * Method to return active tab selection
   * @returns true/false if active
   */
  isActive (key: string) {
    return this.activeTab === key;
  }

  /**
   * Set sidebar status (collapsed/open)
   */
  setSidebarStatus () {
    if (this.isMobile) {
      this.isSideBarCollapsed = true;
    } else {
      this.isSideBarCollapsed = false;
    }
    this.sharedService.sideBarStatus.next(this.isSideBarCollapsed);
  }

  /**
   * Set initial active tab
   */
  setInitialActiveTab (): void {
    if (this.router.url.includes(NavigationScreen.ADMIN_DASHBOARD)) {
      this.sharedService.activeTab.next(SideBarTabs.DASHBOARD);
    } else if (this.router.url.includes(NavigationScreen.CUSTOMERS)) {
      this.sharedService.activeTab.next(SideBarTabs.CUSTOMERS);
    } else if (this.router.url.includes(NavigationScreen.ADMIN_USERS)) {
      this.sharedService.activeTab.next(SideBarTabs.ADMIN_USERS);
    }
  }

  /**
   * Method to dismiss the modal if user clicks on close or outside the edit profile modal
   */
  dismissEditProfileModal () {
    modals.editProfileModal = !modals.editProfileModal;
    this.profileData = this.profile;
    this.setInitialActiveTab();
  }

  /**
   * Method to handle input event for fields
   * @param event to fetch input value from event
   * @param value to fetch type of input field
   */
  inputEventHandler (event: any, value: string) {
    switch (value) {
    case this.inputFieldKey.NAME:
      this.profileData.name = event?.target?.value;
      break;
    case this.inputFieldKey.PREFIX:
      this.profileData && (this.profileData.contact.phone.prefix = event);
      break;
    case this.inputFieldKey.NUMBER:
      this.profileData.contact.phone.number = event?.target?.value;
      break;
    case this.inputFieldKey.PREVIOUS_PASSWORD:
      this.inputValue.changePassword.previousPassword = event?.target?.value;
      break;
    case this.inputFieldKey.NEW_PASSWORD:
      this.inputValue.changePassword.newPassword = event?.target?.value;
      break;
    case this.inputFieldKey.CONFIRM_PASSWORD:
      this.inputValue.changePassword.confirmPassword = event?.target?.value;
      break;
    case this.inputFieldKey.PROFILE_PICTURE:
      this.profileData.picture = event;
      break;
    default:
      break;
    }
  }

  /**
   * Method to handle submission of forms
   * @param value to fetch type of form
   */
  onContinueClick (value: string) {
    switch (value) {
    case this.buttonKey.CHANGE_PASSWORD:
      // Check Previous Password Validation
      this.showError.previousPassword =
          this.sharedService.validateInput(
            this.inputValue.changePassword.previousPassword,
          ) ||
          this.sharedService.isValidPassword(
            this.inputValue.changePassword.previousPassword,
          );
      if (this.showError.previousPassword) {
        // Validate Correct Previous Password Regex
        if (
          this.sharedService.isValidPassword(
            this.inputValue.changePassword.previousPassword,
          )
        ) {
          this.alertService.showAlert(
            'Password should be at least 8 characters with one uppercase, one lowercase, one numeric and one special character',
          );
        }
        return;
      }

      // Check New Password Validation
      this.showError.newPassword =
          this.sharedService.validateInput(
            this.inputValue.changePassword.newPassword,
          ) ||
          this.sharedService.isValidPassword(
            this.inputValue.changePassword.newPassword,
          );
      if (this.showError.newPassword) {
        // Validate Correct New Password Regex
        if (
          this.sharedService.isValidPassword(
            this.inputValue.changePassword.newPassword,
          )
        ) {
          this.alertService.showAlert(
            'Password should be at least 8 characters with one uppercase, one lowercase, one numeric and one special character',
          );
        }
        return;
      }

      // Check Confirm New Password Validation
      this.showError.confirmPassword =
          this.sharedService.validateInput(
            this.inputValue.changePassword.confirmPassword,
          ) ||
          this.sharedService.isValidPassword(
            this.inputValue.changePassword.confirmPassword,
          );
      if (this.showError.confirmPassword) {
        // Validate Correct Confirm Password Regex
        if (
          this.sharedService.isValidPassword(
            this.inputValue.changePassword.confirmPassword,
          )
        ) {
          this.alertService.showAlert(
            'Password should be at least 8 characters with one uppercase, one lowercase, one numeric and one special character',
          );
        }
        return;
      }

      if (
        this.inputValue.changePassword.newPassword !==
          this.inputValue.changePassword.confirmPassword
      ) {
        this.showError.confirmPassword = true;
        this.alertService.showAlert(
          'New and Confirm Password does not match',
        );
        return;
      }
      if (
        !this.showError.previousPassword &&
          !this.showError.newPassword &&
          !this.showError.confirmPassword
      ) {
        // API Call - Change Password
        this.changePassword();
      }
      break;
    case this.buttonKey.EDIT_PROFILE:
      // Check Name Validation
      this.showError.name = this.sharedService.validateInput(
        this.profileData.name,
      );
      if (this.showError.name) {
        return;
      }

      this.showError.number = this.sharedService.validateInput(
        this.profileData.contact.phone.number,
      );
      if (this.showError.number) {
        return;
      }

      // API Call - Update Profile
      this.updateProfile();
      break;
    }
  }

  onChangePasswordClick () {
    // Reset Change Password Input Fields
    this.resetChangePasswordFields();

    // Dismiss Profile Modal
    modals.profileModal = !modals.profileModal;

    // Show Change Password Modal
    modals.changePasswordModal = !modals.changePasswordModal;
  }

  onEditProfileClick () {
    // Set Profile Picture Name
    this.profileData.fileName = this.sharedService?.extractFileNameFromUrl(
      this.profileData?.picture,
    );

    this.modals.profileModal = !this.modals.profileModal;
    this.modals.editProfileModal = !this.modals.editProfileModal;
  }

  /**
   * Reset Change Password Input Fields
   */
  resetChangePasswordFields () {
    // Reset Input Fields
    this.inputValue.changePassword.previousPassword = '';
    this.inputValue.changePassword.newPassword = '';
    this.inputValue.changePassword.confirmPassword = '';

    // Reset Validations
    this.showError = {
      previousPassword: false,
      newPassword: false,
      confirmPassword: false,
    };
  }

  /** Default Sidebar Tabs */
  defaultSidebarTabs = [
    {
      title: 'Dashboard',
      key: SideBarTabs.DASHBOARD,
      image: `ic-dashboard-${ this.theme }.svg`,
      permission: ApiConstants.Permissions.DASHBOARD_READ,
    },
    {
      title: 'Customers',
      key: SideBarTabs.CUSTOMERS,
      image: `ic-customers-${ this.theme }.svg`,
      permission: ApiConstants.Permissions.CUSTOMERS_READ,
    },
    {
      title: 'Admin Users',
      key: SideBarTabs.ADMIN_USERS,
      image: `ic-admin-users-${ this.theme }.svg`,
      permission: ApiConstants.Permissions.ADMIN_USERS_READ,
    },
    {
      title: 'Profile',
      key: SideBarTabs.PROFILE,
      image: `ic-profile-${ this.theme }.svg`,
      permission: ApiConstants.Permissions.DEFAULT,
    },
    {
      title: 'Logout',
      key: SideBarTabs.LOGOUT,
      image: `ic-logout-${ this.theme }.svg`,
      permission: ApiConstants.Permissions.DEFAULT,
    },
  ];

  /**
   * Set Sidebar Tabs By User Permissions
   */
  setSidebarTabs () {
    this.sideBarTabs = [];
    if (this.profileData.type === ApiConstants.UserType.ADMIN) {
      this.sideBarTabs = this.defaultSidebarTabs;
    } else {
      this.sideBarTabs = this.defaultSidebarTabs
        .filter((item) =>
          this.profileData._role.permissions.includes(item.permission),
        )
        .map((item) => ({
          title: item.title,
          key: item.key,
          image: item.image,
          permission: item.permission.split('.')[0],
        }));
    }
  }

  /**
   * Clear User Data from Local Storage
   */
  clearUserData () {
    localStorage.removeItem(AppConstants.LocalStorageKeys.AUTH_TOKEN);
    localStorage.removeItem(AppConstants.LocalStorageKeys.STATUS_LIST);
    localStorage.removeItem(AppConstants.LocalStorageKeys.INCIDENT_ID);

    setTimeout(() => {
      // Navigate to Login Screen
      this.router.navigate([ '/' ]);
    }, 10);
  }

  /**
   * Success Modal Continue CLick
   */
  successModalClick () {
    if (!this.isUpdateProfile) {
      // Clear User Data
      this.clearUserData();
    }
    this.modals.successModal = !this.modals.successModal;
  }

  /**
   * API Call - Get Profile
   */
  async updateProfile () {
    // Show Loading
    this.sharedService.showLoading();

    // Prepare Body Request
    const json = {
      name: this.profileData.name,
      phone: {
        prefix: this.profileData.contact.phone.prefix,
        number: this.profileData.contact.phone.number,
      },
      picture: this.profileData.picture ?? '',
    };

    // Get API Response
    const apiResponse = await this.authService.updateProfile(json);

    // Consume Response
    switch (apiResponse?.responseType) {
    case NetworkResponseType.SUCCESS:
      if (apiResponse?.response?.success) {
        // Set User Profile Data
        this.sharedService.setProfileData(apiResponse?.response);

        // Set User Profile Data
        this.profile = apiResponse?.response?.data;

        // Set Profile Picture Name
        this.profileData.fileName =
            this.sharedService?.extractFileNameFromUrl(
              apiResponse?.response?.data?.picture,
            );

        // Dismiss Edit Profile Modal
        this.modals.editProfileModal = !this.modals.editProfileModal;

        // Set Modal Click Status
        this.isUpdateProfile = true;

        // Show Success Modal
        this.successMessage = 'Profile Updated Successfully';
        this.modals.successModal = !this.modals.successModal;

        // Set Profile Picture
        this.setProfilePicture();

        // Dismiss Loading
        this.sharedService.dismissLoading();
        return;
      }

      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.API_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.UNKNOWN_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;
    }

    // Dismiss Loading
    this.sharedService.dismissLoading();
  }

  /**
   * API Call - Change Password
   */
  async changePassword () {
    // Show Loading
    this.sharedService.showLoading();

    // Prepare Body Request
    const json = {
      oldPassword: this.inputValue?.changePassword?.previousPassword,
      newPassword: this.inputValue?.changePassword?.newPassword,
      confirmPassword: this.inputValue?.changePassword?.confirmPassword,
    };

    // Get API Response
    const apiResponse = await this.authService.changePassword(json);

    // Consume Response
    switch (apiResponse?.responseType) {
    case NetworkResponseType.SUCCESS:
      if (apiResponse?.response?.success) {
        // Clear Fields
        this.inputValue.changePassword = {
          previousPassword: '',
          newPassword: '',
          confirmPassword: '',
        };

        // Set Modal Click Status
        this.isUpdateProfile = false;

        // Show Success Message
        this.successMessage =
            apiResponse?.response?.message ?? 'Change Password Successful';
        this.modals.changePasswordModal = !this.modals.changePasswordModal;
        this.modals.successModal = !this.modals.successModal;

        // Dismiss Loading
        this.sharedService.dismissLoading();
        return;
      }

      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.API_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.UNKNOWN_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;
    }

    // Dismiss Loading
    this.sharedService.dismissLoading();
  }

  /**
   * API Call - Logout
   */
  async requestLogout () {
    // Show Loading
    this.sharedService.showLoading();

    // Get API Response
    const apiResponse = await this.authService.logout();

    // Consume Response
    switch (apiResponse?.responseType) {
    case NetworkResponseType.SUCCESS:
      if (apiResponse?.response?.success) {
        // Dismiss Logout Modal
        this.modals.logoutModal = !this.modals.logoutModal;

        // Show Message
        this.alertService.showAlert(
          apiResponse?.response?.message ?? 'Logged out successfully!',
          AlertType.SUCCESS,
        );

        // Clear User Data
        this.clearUserData();
        // Dismiss Loading
        this.sharedService.dismissLoading();
        return;
      }

      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.API_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.UNKNOWN_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;
    }

    // Dismiss Loading
    this.sharedService.dismissLoading();
  }
}
