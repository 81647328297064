import { CanActivate, CanLoad, Router } from '@angular/router';
import { AppConstants } from '../constants/AppConstants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor (private router: Router) {
    //Empty Constructor
  }

  canActivate () {
    if (!localStorage.getItem(AppConstants.LocalStorageKeys.AUTH_TOKEN)) {
      this.router.navigate([ '/' ]);
      return false;
    }
    return true;
  }

  canLoad () {
    if (!localStorage.getItem(AppConstants.LocalStorageKeys.AUTH_TOKEN)) {
      this.router.navigate([ '/' ]);
      return false;
    }
    return true;
  }
}
