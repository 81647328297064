import { AlertType, AppConstants } from 'src/app/constants/AppConstants';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConstants } from 'src/app/constants/ApiConstants';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CommonService } from 'src/app/services/common/common.service';
import { FilterData } from 'src/app/models/FilterData';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: [ './table.component.scss' ],
})
export class TableComponent implements OnInit {
  staticText: any;
  // selectedOption: any;
  showOptions = false;
  showOptionDropdown = false;
  rowIndexForOption = -1;
  /**
   * Property to handle theme
   */
  @Input() public theme = 'light';

  /**
   * Property to display checkboxes in table
   */
  @Input() public showCheckBox = true;

  // @Input() public showHeaderCheckBox = true;

  /**
   * Property to display pagination in table
   */
  @Input() public showPagination = true;

  /**
   * Property to emit event any action event is clicked
   */
  @Output() actionEvent = new EventEmitter();

  /**
   * Property to emit event any action event is clicked
   */
  @Output() toggleEvent = new EventEmitter();

  /**
   * Property to emit event any action event is change
   */
  @Output() checkboxClickHandler = new EventEmitter();

  /**
   * Property to emit event when load more is clicked
   */
  @Output() loadMoreEvent = new EventEmitter();

  /**
   * Property to hold search input text
   */
  @Input() searchText = '';

  /**
   * Property to emit search event
   */
  @Output() searchEvent = new EventEmitter();

  /**
   * Property to emit event when delete icon is clicked
   */
  @Output() deleteIconEvent = new EventEmitter();

  /**
   * Property to emit event when refresh icon is clicked
   */
  @Output() refreshIconEvent = new EventEmitter();

  /**
   * Property to show load more button for pagination
   */
  @Input() isPaginationVisible = false;

  /**
   * Property to show buttons
   */
  @Input() showFilterButtons = true;

  /**
   * Property to filter search
   */
  @Input() showFilters = false;

  /**
   * Property to show filter, search bar
   */
  @Input() showFilterBar = true;

  /**
   * Property to show filter, search bar
   */
  @Input() showDeleteIcon = true;

  /**
   * Property to show Refresh Icon
   */
  @Input() showRefreshIcon = false;

  /**
   * Property to hold student table status
   */
  @Input() isStudentTable = false;

  /**
   * Property to show filter drop down
   */
  @Input() showFilterButton = true;

  /**
   * Property to handle search input field
   */
  @ViewChild('searchInput') searchInput!: ElementRef;

  /**
   * Property to handle filter bar button click event
   */
  @Output() filterButtonEvent = new EventEmitter();

  /**
   * Property to handle filter bar selected option click event
   */
  @Output() selectedOptionEvent = new EventEmitter();

  /**
   * Property to handle filter bar selected option click event
   */
  @Output() filterSelectedOptionEvent = new EventEmitter();

  /**
   * Property to handle table item click event
   */
  @Output() itemSelectEvent = new EventEmitter();

  /**
   * Property to handle icons click
   */
  @Output() iconClickEvent = new EventEmitter();

  /* Property to emit copy event */
  @Output() copyEvent = new EventEmitter();

  /**
   * Property to show filter bar Buttons
   */
  @Input() filterButtons = [
    { type: 'primary', text: 'Export Report', event: 'export' },
    { type: 'primary', text: 'Add Prediction', event: 'prediction' },
  ];

  /**
   * Property to hold dummy values
   */
  @Input() tableData: any = {
    thead: [
      {
        id: 'name',
        text: 'Name',
        type: 'inputText',
        isDropDown: false,
        color: [
          { key: 'Yes', color: 'primary' },
          { key: 'No', color: 'success' },
        ],
        permission: '',
      },
      {
        id: 'email',
        text: 'Email',
        type: 'inputText',
        isDropDown: false,
        permission: '',
      },
      {
        id: 'select',
        type: 'select',
        dropdownOptions: [
          {
            title: 'Option1',
            id: 'option1',
          },
          {
            title: 'Option2',
            id: 'option2',
          },
        ],
        permission: '',
      },
      {
        id: 'read',
        text: 'Read',
        type: 'checkbox',
        isDropDown: false,
        permission: '',
      },
      {
        id: 'phoneNumber',
        text: 'Phone Number',
        type: 'inputText',
        isDropDown: false,
        permission: '',
      },
      {
        id: 'toggle',
        text: 'Active/Inactive',
        type: 'toggle',
        isDropDown: false,
        permission: '',
      },
      {
        id: 'actions',
        text: 'Action',
        type: 'actions',
        isDropDown: false,
        actions: [
          { type: AppConstants.ButtonType.EDIT, text: 'Edit' },
          { type: AppConstants.ButtonType.DELETE, text: 'Delete' },
          { type: AppConstants.ButtonType.BLOCK, text: 'Block' },
        ],
        permission: '',
      },
    ],
  };

  // START : Filter Dropdown Code
  /**
   * Filter Options List
   */
  @Input() filterOptionsList: FilterData[] = [];

  /** Selected Filter Option Data */
  selectedFilterOption: FilterData = { id: '', title: '' };
  // END : Filter Dropdown Code

  /**
   * Property to hold selected item for selected checkboxes
   */
  public selectedElements: string[] = [];

  /** Property to permissions list */
  permissionArray: Array<string> = [];

  /** Property to hold user type value */
  userType: string = '';

  /**
   * Primary Constructor
   */
  constructor (
    public sharedService: SharedService,
    public authService: AuthenticationService,
    public alertService: AlertService,
    public commonService: CommonService,
  ) {
    // Empty Constructor
  }

  // Init Method
  ngOnInit (): void {
    // Get Profile Data
    this.sharedService.profileData.subscribe((profile) => {
      this.permissionArray = profile?.data?._role?.permissions;
      this.userType = profile?.data?.type?.toUpperCase();
    });

    if (document.body.classList.contains('body-dark')) {
      this.theme = 'dark';
    } else {
      this.theme = 'light';
    }
  }

  // START : Filter Dropdown Handling
  /**
   * Update Selected Filter Option Data
   */
  onFilterOptionSelect (option: FilterData) {
    if (this.selectedFilterOption?.id !== option?.id) {
      // Store Selected Filter Option
      this.selectedFilterOption = {
        id: option.id,
        title: option.title,
      };

      // Emit selected filter data event
      this.filterSelectedOptionEvent.emit(this.selectedFilterOption);
    } else {
      this.selectedFilterOption = {
        id: '',
        title: '',
      };
      // Emit selected filter data event
      this.filterSelectedOptionEvent.emit(this.selectedFilterOption);
    }

    // Hide Filter Options Dropdown
    this.showFilters = !this.showFilters;
  }
  // END : Filter Dropdown Handling

  /**
   * Filter Bar Buttons Click Event
   */
  triggerClickEvent (button: any) {
    this.filterButtonEvent.emit(button);
  }

  /**
   * Action Buttons Click Event
   */
  public actionEventHandler (action: any, index: number, item: any) {
    this.actionEvent.emit({ action, index, item });
  }

  /**
   * Clickable Title Click Event
   */
  public onClickActionHandler (header, item) {
    if (header?.isClickable) {
      this.itemSelectEvent.emit(item);
    }
  }

  /**
   * Delete Icon Click
   */
  public onDeleteIconClick () {
    if (this.selectedElements.length > 0) {
      this.deleteIconEvent.emit();
    }
  }

  /**
   * Refresh Icon Click
   */
  public onRefreshIconClick () {
    this.refreshIconEvent.emit();
  }

  /**
   * Checkbox Click Handling
   */
  public checkboxHandler (id: string, type: string) {
    if (type === 'single') {
      if (this.selectedElements.includes(id)) {
        this.selectedElements = this.selectedElements.filter((i) => i !== id);
      } else {
        this.selectedElements.push(id);
      }
    } else {
      if (this.selectedElements.length === this.tableData.tbody.length) {
        this.selectedElements = [];
      } else {
        this.selectedElements = [];
        this.tableData.tbody.map((item: any) =>
          this.selectedElements.push(item._id),
        );
      }
    }
    this.checkboxClickHandler.emit(this.selectedElements);
  }

  /** Filter item select handling */
  selectEventHandler (option, index, header) {
    this.selectedOptionEvent.emit({
      option,
      index: this.rowIndexForOption,
      header,
    });
    this.showOptions = false;
    this.showOptionDropdown = !this.showOptionDropdown;
  }

  /**
   * Pagination Event
   */
  paginateUsers () {
    this.loadMoreEvent.emit(true);
  }

  /**
   * Clickable Title Click Event
   */
  getClass (header: any, body: any) {
    const colorSchema =
      header.type === 'select' ? header.dropdownOptions : header?.color || [];
    if (!colorSchema.length) {
      return '';
    }
    const textIndex = colorSchema.findIndex(
      (item: { key: string; color: string }) => item.key === body[header.id],
    );
    return colorSchema[textIndex]?.color || '';
  }

  public toggleActionHandler (event: any, index: number, item: any) {
    this.toggleEvent.emit({ event, item, index });
  }

  /** Search event handling */
  public searchHandler (event: any) {
    this.searchEvent.emit(event?.target?.value);
  }

  showActions (permission: any) {
    return this.permissionArray?.includes(permission);
  }

  /**
   * Item Dropdown Click
   */
  onDropdownClick (header: any, hasPermission: boolean) {
    if (hasPermission) {
      header.isDropDown && (this.showOptions = !this.showOptions);
    }
  }

  /**
   * Copy Icon Click
   */
  public copyToClipboard (header, item) {
    if (header?.isCopy) {
      this.copyEvent.emit(item);
      this.alertService.showAlert('Copied to clipboard', AlertType.INFO);
    }
  }

  /**
   * Function to hide filters when clicking outside the filter button
   */
  @HostListener('document:click', [ '$event' ])
  hideFiltersOnClickOutside (event: MouseEvent) {
    const filterButton = document.querySelector('.filter-button');
    if (!filterButton || !filterButton.contains(event.target as Node)) {
      this.showFilters = false;
    }
  }
}
