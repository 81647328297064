import { AlertService } from '../alert/alert.service';
import { AppConstants } from 'src/app/constants/AppConstants';
import { AuthenticationService } from '../authentication/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NetworkResponseType } from '../network/NetworkResponse';
import { ProfileModel } from 'src/app/models/ProfileModel';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor (
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private alertService: AlertService,
  ) {
    // Empty Constructor
  }

  isMobile: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private showNavigation = true;

  setShowNavigation (show: boolean): void {
    this.showNavigation = show;
  }

  getShowNavigation (): boolean {
    return this.showNavigation;
  }
  getIsMobileValue (): any {
    return this.isMobile.getValue();
  }

  updateIsMobileValue (newValue: any): void {
    this.isMobile.next(newValue);
  }

  /**
   * API Call - Get Profile
   */
  async getProfile (): Promise<ProfileModel> {
    // Show Loading
    this.sharedService.showLoading();

    // Get API Response
    const apiResponse = await this.authService.getProfile();

    // Consume Response
    switch (apiResponse?.responseType) {
    case NetworkResponseType.SUCCESS:
      if (apiResponse?.response?.success) {
        if (apiResponse?.response?.data !== null) {
          // Dismiss Loading
          this.sharedService.dismissLoading();

          // Set Data in Shared Service
          this.sharedService.setProfileData(apiResponse?.response);

          // Return Profile Data Response
          return apiResponse?.response;
        }
        // Dismiss Loading
        this.sharedService.dismissLoading();
        return;
      }

      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.API_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.UNKNOWN_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;
    }

    // Dismiss Loading
    this.sharedService.dismissLoading();
  }

}
