import {
  NavigationScreen,
  SideBarTabs,
} from 'src/app/constants/navigation-screen';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectHandlerService {
  constructor (private router: Router) {
    //
  }

  redirectTo (type: SideBarTabs) {
    switch (type) {
    case SideBarTabs.DASHBOARD:
      this.router.navigate([ NavigationScreen.ADMIN_DASHBOARD ]);
      break;
    case SideBarTabs.CUSTOMERS:
      this.router.navigate([ NavigationScreen.CUSTOMERS ]);
      break;
    case SideBarTabs.ADMIN_USERS:
      this.router.navigate([ NavigationScreen.ADMIN_USERS ]);
      break;
    default:
      break;
    }
  }
}
