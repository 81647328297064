import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  NavigationScreen,
  SideBarTabs,
} from '../../constants/navigation-screen';
import { AlertService } from '../alert/alert.service';
import { ApiConstants } from '../../constants/ApiConstants';
import { AppConstants } from 'src/app/constants/AppConstants';
import { Injectable } from '@angular/core';
import { ProfileData } from 'src/app/models/ProfileData';
import { ProfileModel } from '../../models/ProfileModel';
import { Router } from '@angular/router';
import countries from '../../../assets/statics/countries.json';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  theme = this.isDarkTheme ? 'dark' : 'light';

  // START - Loaders
  showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showTableLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  inputFieldLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // END - Loaders

  // Profile
  profileData: BehaviorSubject<ProfileModel> = new BehaviorSubject<any>({});

  // open | collapsed
  sideBarStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  activeTab: BehaviorSubject<SideBarTabs> = new BehaviorSubject(
    SideBarTabs.DASHBOARD,
  );

  // Refresh route
  refreshRoute: Subject<any> = new Subject();

  constructor (private alertService: AlertService, private router: Router) {
    //Empty Constructor
  }

  public emailRegex =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public passwordRegex =
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;

  public urlRegex =
    // eslint-disable-next-line no-useless-escape
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;

  /**
   * Property to display selected country in dropdown
   */
  selectedCountry = {
    dial_code: '+1',
    flag: 'assets/statics/flags/ad.svg',
    characters: '9',
  };

  /**
   * Property to hold search text in dropdown
   */
  searchText = '';

  /**
   * Property to hold all countries
   */
  countries: any = [];

  /**
   * Property to hold searched countries
   */
  searchedCountries: any = [];

  // Start Loader
  showLoading () {
    this.showLoader.next(true);
  }

  // Stop Loader
  dismissLoading () {
    this.showLoader.next(false);
  }

  // Start/Stop Table Loader
  tableLoader (action: 'start' | 'stop') {
    this.showTableLoader.next(action === 'start');
  }

  // Show Input Field Loader
  showInputFieldLoader () {
    this.inputFieldLoader.next(true);
  }

  // Dismiss Input Field Loader
  dismissInputFieldLoader () {
    this.inputFieldLoader.next(false);
  }

  checkInput (event: any, type?: string, limit?: number): boolean {
    const isNumericKey = (keyCode) =>
      (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);

    const isAlphabeticKey = (keyCode) =>
      (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105);

    if (type === 'number') {
      return (
        (event.keyCode >= 35 && event.keyCode <= 40) ||
        [ 46, 13, 8, 9, 27, 110, 190 ].includes(event.keyCode) ||
        (event.target.value.length !== limit && isNumericKey(event.keyCode))
      );
    } else {
      return true;
    }
  }

  /**
   * Method returns countries list from json
   * @returns countries list
   */
  async getAllCountries () {
    try {
      return await countries;
    } catch (error) {
      console.error('Error fetching static text:', error);
      throw error;
    }
  }

  // Switch Theme
  switchTheme (value: boolean) {
    this.isDarkTheme = value;
    document.body.classList.toggle('body-dark', value);
  }

  /**
   * Validates an input value to check if it's empty or not.
   * @param inputValue The input value to be validated.
   * @returns Returns true if the input value is empty, otherwise false.
   */
  public validateInput (inputValue?: string): boolean {
    let isError = false;
    // If inputValue's length is falsy (null, undefined, or zero), it's considered empty
    if (!inputValue || !inputValue.length) {
      isError = true;
    } else {
      isError = false;
    }
    return isError;
  }

  /**
   * Checks if the input value is not null, not undefined, and not an empty string.
   * @param value The input value to be checked.
   * @returns Returns true if the value is not null, not undefined, and not an empty string; otherwise, returns false.
   */
  public isNotNullOrEmpty (value?: string | undefined | null): boolean {
    return value && value !== '';
  }

  /**
   * Validates an input value to check if it's a valid email or not.
   * @param inputValue The input value to be validated.
   * @returns Returns true if the input value is valid email, otherwise false.
   */
  public isValidEmail (inputValue?: string): boolean {
    if (!inputValue || !inputValue.length) return false;
    return !inputValue.match(new RegExp(this.emailRegex));
  }

  /**
   * Validates an input value to check if it matches the specified password criteria.
   * Modify the regex pattern and criteria as needed for password validation.
   * @param inputValue The input value to be validated as a password.
   * @returns Returns true if the input value matches the password criteria, otherwise false.
   */
  public isValidPassword (inputValue?: string): boolean {
    if (!inputValue || !inputValue.length) return false;

    return !this.passwordRegex.test(inputValue);
  }

  /**
   * Validates a confirmed password to check if it matches the original password.
   * @param password The original password against which the confirmation is checked.
   * @param confirmPassword The confirmed password value to be validated.
   * @returns Returns true if the confirmed password matches the original password, otherwise false.
   */
  public isValidConfirmPassword (
    password?: string,
    confirmPassword?: string,
  ): boolean {
    // Check if input password and confirm password is not null/undefined/empty
    if (
      !password ||
      !password.length ||
      !confirmPassword ||
      !confirmPassword.length
    )
      return false;

    // Check if the confirmed password meets the password criteria using the password regex
    const confirmPasswordValid = !confirmPassword.match(
      new RegExp(this.passwordRegex),
    );

    // Check if the confirmed password matches the original password
    const passwordsMatch = password === confirmPassword;

    // Return true if both condition true
    return passwordsMatch && !confirmPasswordValid;
  }

  /**
   * @param profileModel Logged In User Profile Data
   * */
  public setProfileData (profileModel: ProfileModel) {
    this.profileData.next(profileModel);
  }

  /**
   * Redirects to a specific tab based on permissions.
   * @param permissions - An array of permission strings.
   */
  navigateToTab (permissions: string[]) {
    if (permissions?.includes(ApiConstants.Permissions.DASHBOARD_READ)) {
      return this.router.navigate([ NavigationScreen.ADMIN_DASHBOARD ]);
    }  else if (
      permissions?.includes(ApiConstants.Permissions.CUSTOMERS_READ)
    ) {
      return this.router.navigate([ NavigationScreen.CUSTOMERS ]);
    } else if (
      permissions?.includes(ApiConstants.Permissions.ADMIN_USERS_READ)
    ) {
      return this.router.navigate([ NavigationScreen.ADMIN_USERS ]);
    } else {
      return this.router.navigate([ NavigationScreen.ADMIN_DASHBOARD ]);
    }
  }

  /**
   * Navigate to Dashboard Screen
   */
  navigateToDashboard () {
    return this.router.navigate([ NavigationScreen.ADMIN_DASHBOARD ]);
  }

  toTitleCase (input: string): string {
    if (!input) {
      return '';
    }
    return input
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  /**
   * Function to perform a deep copy on an object
   * @param obj - The object to be deeply copied
   * @returns A new object that is a deep copy of the input object
   */
  createDeepCopy (obj: any): any {
    if (!obj) {
      return null;
    }
    return JSON.parse(JSON.stringify(obj));
  }

  public getCookie (name: string): string {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${ name }\\s*=\\s*([^;]+)`,
    );
    return cookieValue ? cookieValue.pop() : '';
  }

  public setCookie (name: string, value: string, days: number) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    document.cookie = `${ name }=${ value }; expires=${ expirationDate.toUTCString() }; path=/`;
  }

  /**
   * Checks if the value is not null, not undefined, and not empty, and if it is a valid image URL pattern.
   * @param value The input value to be checked.
   * @returns Returns true if the value matches all conditions
   */
  public isValidImageUrl (value?: string | undefined | null): boolean {
    if (!value || value.trim() === '') {
      return false;
    }

    // Regular expression pattern for a simple image URL check
    const imageUrlPattern = /\.(jpeg|jpg|gif|png)$/i;

    // Check if the value is a valid image URL based on the regex
    return imageUrlPattern.test(value.trim());
  }

  /**
   * Download CSV File
   */
  public downloadCSV (response: any, fileName?: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.setAttribute('target', '_self');
    a.href = `data:text/csv,${ response }`;
    a.download = fileName ?? 'file';
    a.click();
    window.URL.revokeObjectURL(response);
    a.remove();
  }

  /**
   * Download Sample CSV
   */
  public downloadSampleCSV () {
    const link = document.createElement('a');
    link.href =
      'https://pastorl.s3.amazonaws.com/files/sample-students-data.csv';
    link.target = '_blank'; // Open the link in a new tab/window
    link.download = 'downloaded-file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Extract File Name From URL
   */
  public extractFileNameFromUrl (url?: string): string {
    if (!url) return;
    const urlParts = url.split('/');
    const fileNameWithTimestamp = urlParts[urlParts.length - 1];
    const fileNameParts = fileNameWithTimestamp.split('_');
    const fileName = fileNameParts.slice(1).join('_');
    return fileName;
  }

  /**
   * Get Current Country
   */
  async getCurrentCountry (): Promise<string> {
    try {
      const countries = await this.getAllCountries();
      const currentCountryString: string | null = localStorage.getItem(
        AppConstants.LocalStorageKeys.LOCATION,
      );
      const currentCountry = JSON.parse(currentCountryString || 'null');
      const country = countries.find((country: any) =>
        (country.code || '').match(new RegExp(currentCountry.countryCode, 'gi')),
      );

      return country.dial_code;
    } catch (error) {
      console.error('Error getting current country:', error);
      throw error;
    }
  }

  /**
   * Method to return string initials
   *
   * @param fullName String to get initials
   * @returns String initials
   */
  getNameInitials (fullName?: string) {
    // Check Name Validation
    if (!fullName) return;

    const nameParts = fullName.split(' ');

    let name = '';

    // Check if more than one word name
    if (nameParts.length > 1) {
      // Get First Character of First Name
      const firstInitial = nameParts[0].charAt(0);

      // Get First Character of Last Name
      const secondInitial = nameParts[nameParts.length - 1].charAt(0);

      // Return Initials
      name = `${ firstInitial }${ secondInitial }`;
      return name.toUpperCase();
    } else if (fullName.length > 1) {
      // Check if only one word name
      // Return two initials of first name
      const firstInitial = nameParts[0].charAt(0);
      const secondInitial = nameParts[0].charAt(1) || '';
      name = `${ firstInitial }${ secondInitial }`;
      name = `${ firstInitial }${ secondInitial }`;
      return name.toUpperCase();
    } else {
      // Return the single character if the name is only one character long
      return fullName.toUpperCase();
    }
  }

  showActions (permission: any) {
    let value = false;
    this.profileData.subscribe((profile) => {
      const data: ProfileData = this.createDeepCopy(profile?.data);
      if (data?.type?.toUpperCase() === ApiConstants.UserType.ADMIN) {
        value = true;
      } else {
        value = data?._role?.permissions?.includes(permission);
      }
    });
    return value;
  }

  /**
   * Method to return random color
   */
  randomColor (): string {
    const randomRed = Math.floor(Math.random() * 256);
    const randomGreen = Math.floor(Math.random() * 256);
    const randomBlue = Math.floor(Math.random() * 256);
    return `rgb(${ randomRed }, ${ randomGreen }, ${ randomBlue })`;
  }

  /**
   * Request to Clear User Data
   *
   * @return Promise with Result
   * */
  clearUserData (): void {
    localStorage.removeItem(AppConstants.LocalStorageKeys.AUTH_TOKEN);
    localStorage.removeItem(AppConstants.LocalStorageKeys.STATUS_LIST);
    localStorage.removeItem(AppConstants.LocalStorageKeys.INCIDENT_ID);
    localStorage.removeItem(AppConstants.LocalStorageKeys.LOCATION);
    localStorage.removeItem('impersonate');
    window.location.href = '/authentication';
  }
}
