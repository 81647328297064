export class ApiConstants {
  // Predefined Permissions List
  static PredefinePermissions = [
    's-dashboard.read',
    's-dashboard.write',
    's-dashboard.remove',
    's-admin-users.read',
    's-admin-users.write',
    's-admin-users.remove',
    's-customers.read',
    's-customers.write',
    's-customers.remove',
    's-default',
  ];

  // Predefined Permissions List
  static Permissions = {
    // DEFAULT
    DEFAULT: 'default',

    // DASHBOARD
    DASHBOARD_READ: 's-dashboard.read',
    DASHBOARD_WRITE: 's-dashboard.write',
    DASHBOARD_REMOVE: 's-dashboard.remove',

    // CUSTOMERS
    CUSTOMERS_READ: 's-customers.read',
    CUSTOMERS_WRITE: 's-customers.write',
    CUSTOMERS_REMOVE: 's-customers.remove',

    // ADMIN_USERS
    ADMIN_USERS_READ: 's-admin-users.read',
    ADMIN_USERS_WRITE: 's-admin-users.write',
    ADMIN_USERS_REMOVE: 's-admin-users.remove',
  };

  static Status = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  };

  // User Type Keys
  static UserType = {
    SUPERADMIN: 'SUPERADMIN',
    ADMIN: 'ADMIN',
    MEMBER: 'MEMBER',
  };
}
