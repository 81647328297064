<div class="main-container">
  <!-- Top Filter, Search, Buttons Container -->
  <div class="filter-container" *ngIf="showFilterBar">
    <div class="top-bar d-flex">
      <!-- Filter Button -->
      <div
        class="filter-button d-flex align-items-center"
        (click)="showFilters = !showFilters"
        *ngIf="showFilterButton"
      >
        <img
          src="/assets/icons/ic-filter-{{ theme }}.svg"
          class="filter-icon"
        />
        <span>Filters</span>
        <img
          [src]="
            showFilters
              ? '/assets/icons/ic-chevron-up-light.svg'
              : '/assets/icons/ic-chevron-down-light.svg'
          "
          class="chevron-down"
        />
      </div>

      <!-- Search Icon -->
      <div
        [ngClass]="[
          showFilterButton
            ? 'search-icon d-flex align-items-center'
            : 'search-icon-without-filter d-flex align-items-center'
        ]"
        (click)="searchInput.focus()"
      >
        <img
          class="color-change"
          src="/assets/icons/ic-search-{{ theme }}.svg"
        />
      </div>

      <!-- Search Input Field -->
      <input
        class="search-bar"
        #searchInput
        placeholder=" Search..."
        [value]="searchText"
        (change)="searchHandler($event)"
      />

      <!-- Refresh Container -->
      <div
        class="refresh-container cursor-pointer"
        *ngIf="showRefreshIcon"
        (click)="onRefreshIconClick()"
      >
        <img src="assets/icons/ic-refresh.svg" alt="" />
      </div>

      <!-- Delete Container -->
      <div
        class="refresh-container cursor-pointer"
        *ngIf="showDeleteIcon && !isStudentTable"
        (click)="onDeleteIconClick()"
      >
        <img
          class="icon"
          [src]="
            !!selectedElements.length
              ? 'assets/icons/ic-delete-enable.svg'
              : 'assets/icons/ic-delete-disable.svg'
          "
          alt=""
        />
      </div>

      <!-- Buttons -->
      <div *ngFor="let button of filterButtons">
        <div
          class="export-button"
          *ngIf="showFilterButtons && showActions(button.permission)"
        >
          <app-button
            [label]="button.text"
            width="150"
            class="export-button"
            (clickEvent)="triggerClickEvent(button)"
            [borderRadius]="'14px'"
          ></app-button>
        </div>
      </div>
    </div>

    <!-- Filter Dropdown -->
    <div
      *ngIf="showFilters"
      class="filters-dropdown-container"
      (click)="$event.stopPropagation()"
    >
      <div class="filter-dropdown font-12">
        <div
          *ngFor="let option of filterOptionsList"
          class="filter-item-text d-flex justify-content-between"
          (click)="onFilterOptionSelect(option)"
        >
          <span
            [ngClass]="[
              option.id === selectedFilterOption.id
                ? 'selected-option'
                : 'option'
            ]"
          >
            {{ option.title }}</span
          >
          <img
            src="assets/icons/ic-check.svg"
            alt=""
            *ngIf="option.id === selectedFilterOption.id"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Main Table Container -->
  <div
    class="table-container position-relative"
    [ngClass]="!showPagination ? 'table-container-extended' : ''"
  >
    <table class="rt-table">
      <!-- Table Header (Titles) -->
      <thead class="table-header">
        <tr>
          <!-- Checkbox Container -->
          <th
            scope="col"
            *ngIf="showCheckBox && !isStudentTable"
            class="checkbox-header text-center"
          >
            <!-- Checkbox -->
            <input
              (change)="checkboxHandler('', 'all')"
              type="checkbox"
              [checked]="
                !!(
                  selectedElements.length === tableData.tbody.length &&
                  tableData.tbody.length
                )
              "
              class="checkbox cursor-pointer"
            />
          </th>

          <!-- Table Header Titles List -->
          <ng-container *ngFor="let header of tableData.thead">
            <th
              *ngIf="showActions(header.permission)"
              [ngClass]="{
                'text-center padding-left-20': header.showProfileImage
              }"
            >
              {{ header.title }}
            </th>
          </ng-container>
        </tr>
      </thead>

      <!-- Table Body (List Items) -->
      <tbody>
        <tr *ngFor="let body of tableData.tbody; let i = index">
          <!-- Checkbox Container -->
          <td *ngIf="showCheckBox && !isStudentTable" class="text-center">
            <!-- Checkbox -->
            <input
              (change)="checkboxHandler(body._id, 'single')"
              [checked]="selectedElements.includes(body._id)"
              type="checkbox"
              class="checkbox cursor-pointer"
            />
          </td>

          <td
            *ngFor="let header of tableData.thead"
            [style]="header.type === 'select' ? 'overflow: visible' : ''"
            class="font-12"
          >
            <!-- Table Titles (If fontType is primary) -->
            <div
              *ngIf="header.type === 'inputText' && header?.fontType"
              class="row-container"
            >
              <div
                [ngClass]="[
                  header.fontType,
                  header.isClickable ? 'cursor-pointer' : ''
                ]"
                (click)="onClickActionHandler(header, body)"
              >
                {{ body[header.id] }}
              </div>
              <div>
                <img
                  *ngIf="header.isCopy"
                  src="/assets/icons/ic-copy.svg"
                  class="copy-icon cursor-pointer"
                  (click)="copyToClipboard(header, body)"
                />
              </div>
            </div>

            <!-- Table Titles (If fontType is not primary) -->
            <div
              *ngIf="header.type === 'inputText' && !header?.fontType"
              [ngClass]="[
                getClass(header, body),
                header.isClickable ? 'cursor-pointer' : ''
              ]"
              (click)="onClickActionHandler(header, body)"
            >
              {{ body[header.id] }}
              <img
                *ngIf="header.isDropDown"
                [ngClass]="[
                  getClass(header, body),
                  header.isClickable ? 'cursor-pointer' : ''
                ]"
                [src]="
                  showFilters
                    ? '/assets/icons/ic-chevron-up-light.svg'
                    : '/assets/icons/ic-chevron-down-light.svg'
                "
                class="chevron-down"
              />
            </div>

            <!-- Toggle Switch Container -->
            <div
              *ngIf="header.type === 'toggle'"
              class="form-group position-relative my-2"
            >
              <!-- Toggle Switch -->
              <app-toggle
                [isChecked]="body[header.id]"
                (clickEvent)="toggleActionHandler($event, i, body)"
                [disabled]="!showActions(header.editPermission)"
              ></app-toggle>
            </div>

            <!-- Action Buttons Container -->
            <div *ngIf="showActions(header.permission)" class="d-flex">
              <!-- Action Buttons List -->
              <span
                *ngFor="let action of header.actions; let j = index"
                [ngClass]="action.type"
                class="action-button"
                (click)="actionEventHandler(action, i, body)"
              >
                <!-- Action Button -->
                <app-button
                  [type]="action.type"
                  [label]="action.title"
                  borderRadius="14"
                ></app-button>
              </span>
            </div>

            <div *ngIf="header.type === 'select'">
              <div
                class="filter-button d-flex align-items-center"
                [ngClass]="getClass(header, body)"
                (click)="
                  onDropdownClick(header, showActions(header.editPermission));
                  rowIndexForOption = i
                "
              >
                {{ body[header.id] }}
                <img
                  *ngIf="
                    header.isDropDown && showActions(header.editPermission)
                  "
                  [src]="
                    showOptions && rowIndexForOption === i
                      ? '/assets/icons/ic-chevron-up-light.svg'
                      : '/assets/icons/ic-chevron-down-light.svg'
                  "
                  class="chevron-down ms-2"
                />
              </div>
              <div
                *ngIf="rowIndexForOption === i && showOptions"
                class="tbody-filters-dropdown-container"
                (click)="$event.stopPropagation()"
              >
                <div class="filter-dropdown">
                  <div
                    *ngFor="let option of header.dropdownOptions; let j = index"
                    class="filter-item-text d-flex justify-content-between"
                    (click)="selectEventHandler(option, i, header)"
                  >
                    <span
                      [ngClass]="[
                        option.title === body[header.id]
                          ? 'selected-option'
                          : 'option'
                      ]"
                    >
                      {{ option.title }}</span
                    >
                    <img
                      src="assets/icons/ic-check.svg"
                      alt=""
                      *ngIf="option.title === body[header.id]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="no-data-found-container" *ngIf="!tableData.tbody.length">
      <h3 class="no-data-found-text">No Data Found</h3>
    </div>
  </div>
</div>
